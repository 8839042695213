import { JsonObject, JsonProperty } from 'json2typescript'
import QuoteRequest from '../QuoteRequest'
import AdditionalPolicyholder from '../AdditionalPolicyholder'
import Property from './Property'

@JsonObject('PropertyQuoteRequest')
export default class PropertyQuoteRequest extends QuoteRequest {
  @JsonProperty('properties', [Property])
    properties: Property[] = []

  @JsonProperty('additionalPolicyholder', AdditionalPolicyholder)
    additionalPolicyholder: AdditionalPolicyholder | null = null

  @JsonProperty('yearsWithNoClaimsBuilding', String, true)
    yearsWithNoClaimsBuilding: string | null = null

  @JsonProperty('yearsWithNoClaimsContents', String, true)
    yearsWithNoClaimsContents: string | null = null
}

@JsonObject('PropertyMTAQuoteRequest')
export class PropertyMTAQuoteRequest extends PropertyQuoteRequest {
  @JsonProperty('propertyRiskId', Number, true)
  propertyRiskId: number | null = null

  @JsonProperty('reasonForChange', String)
    reasonForChange = ''
}

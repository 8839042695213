import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '../../converters/DateConverter'
import VAPVehicle from '../models/VAPVehicle'
import Model from './Model'
import { ProposerDriver } from './Driver'
import { DefinedListDetail } from './DefinedList'
import Address from './Address'
import Car from './Car'
import CustomList from './CustomList'
import AdditionalDriver from './AdditionalDriver'
import PetProposer from './PetProposer'
import PetRiskPet from './PetRiskPet'
import GapDemands from './GapDemands'
import GapProposer from './GapProposer'
import TouringCaravanProposer from './TouringCaravanProposer'
import CaravanProposer from './CaravanProposer'
import CaravanCover from './CaravanCoverRequest'
import Caravan from './Caravan'
import TouringCaravan from './TouringCaravan'
import CoInsuranceRule from './CoInsuranceRule'
import Modification from './Modification'
import AdditionalPolicyholder from './AdditionalPolicyholder'
import RiskProposer from './RiskProposer'
import Property from './Property/Property'

@JsonObject('Risk')
export default class Risk extends Model {
  @JsonProperty('effectiveDate', DateConverter)
  effectiveDate: Date

  @JsonProperty('productUniqueId', String)
  productUniqueId: string | null = null

  @JsonProperty('address', Address)
  address: Address

  @JsonProperty('businessSource', CustomList)
  businessSource: CustomList

  @JsonProperty('coInsuranceRule', CoInsuranceRule)
  coInsuranceRule: CoInsuranceRule

  @JsonProperty('vapVehicle', VAPVehicle)
  vapVehicle: VAPVehicle

  @JsonProperty('riskProposer', RiskProposer)
  riskProposer: RiskProposer

  @JsonProperty('breakDownCoverRequired', Boolean, true)
  breakDownCoverRequired: boolean | null = null

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    vapVehicle = new VAPVehicle(),
    riskProposer = new RiskProposer(),
    breakDownCoverRequired = null,
  ) {
    super(id)
    this.productUniqueId = productUniqueId
    this.effectiveDate = effectiveDate
    this.address = address
    this.businessSource = businessSource
    this.coInsuranceRule = coInsuranceRule
    this.vapVehicle = vapVehicle
    this.riskProposer = riskProposer
    this.breakDownCoverRequired = breakDownCoverRequired
  }
}

@JsonObject('CarRiskVehicle')
export class CarRiskVehicle extends Model {
  @JsonProperty('registrationNumber', String)
  registrationNumber: string

  @JsonProperty('value', Number)
  value: number

  @JsonProperty('noOfSeats', Number)
  noOfSeats: number

  @JsonProperty('yearOfManufacture', Number)
  yearOfManufacture: number

  @JsonProperty('purchaseDate', DateConverter)
  purchaseDate: Date

  @JsonProperty('car', Car)
  car: Car

  @JsonProperty('unknownABICode', String)
  unknownABICode: string | null

  @JsonProperty('registeredKeeper', DefinedListDetail)
  registeredKeeper: DefinedListDetail

  @JsonProperty('registeredOwner', DefinedListDetail)
  registeredOwner: DefinedListDetail

  @JsonProperty('overnightParkLocation', DefinedListDetail)
  overnightParkLocation: DefinedListDetail

  @JsonProperty('transmission', DefinedListDetail)
  vehicleTransmission: DefinedListDetail

  @JsonProperty('alarmType', DefinedListDetail)
  alarmType: DefinedListDetail

  @JsonProperty('alarmModel', DefinedListDetail)
  alarmModel: DefinedListDetail

  @JsonProperty('alarmFittedBy', DefinedListDetail)
  alarmFittedBy: DefinedListDetail

  @JsonProperty('trackerType', DefinedListDetail)
  trackerType: DefinedListDetail

  @JsonProperty('trackerModel', DefinedListDetail)
  trackerModel: DefinedListDetail

  @JsonProperty('address', Address)
  address: Address

  @JsonProperty('annualMileage', Number)
  annualMileage: number

  @JsonProperty('businessMileage', Number)
  businessMileage: number

  @JsonProperty('leftHandDrive', Boolean)
  leftHandDrive: boolean

  @JsonProperty('isVehicleRegisteredInUK', Boolean)
  isVehicleRegisteredInUK: boolean | null = null

  @JsonProperty('isUsedForPrivateHire', Boolean)
  isUsedForPrivateHire: boolean | null = null

  @JsonProperty('cubicCapacity', Number)
  cubicCapacity: number

  @JsonProperty('bodyType', String)
  bodyType: string

  @JsonProperty('imported', Boolean)
  imported: boolean

  @JsonProperty('conversionCompleted', Boolean)
  conversionCompleted: boolean

  @JsonProperty('registeredDVLAMotorhome', Boolean)
  registeredDVLAMotorhome: boolean

  @JsonProperty('modifiedEngineOrDrive', Boolean)
  modifiedEngineOrDrive: boolean

  @JsonProperty('motorhomeType', String)
  motorhomeType: string | null

  @JsonProperty('unknownMotorhomeType', String)
  unknownMotorhomeType: string | null

  @JsonProperty('vehicleWeight', Number)
  vehicleWeight: number

  @JsonProperty('hasTrailer', Boolean)
  hasTrailer: boolean | null = null

  @JsonProperty('sectionPermit', Boolean)
  sectionPermit: boolean | null = null

  @JsonProperty('loanWithPermits', Boolean)
  loanWithPermits: boolean | null = null

  @JsonProperty('roadsideProhibition', Boolean)
  roadsideProhibition: boolean | null = null

  @JsonProperty('percentageContract', Number)
  percentageContract: number | null = null

  @JsonProperty('trailerDetails', String)
  trailerDetails: string | null = null

  @JsonProperty('trailerMaxCapacity', Number)
  trailerMaxCapacity: number | null

  @JsonProperty('trailerValue', Number)
  trailerValue: number | null = null

  @JsonProperty('trailerVIN', String)
  trailerVIN: string | null = null

  @JsonProperty('carriesDangerousGoods', Boolean)
  carriesDangerousGoods: boolean | null = null

  @JsonProperty('storedGoodsDetails', String)
  storedGoodsDetails: string | null = null

  @JsonProperty('typeOfGoods', String)
  typeOfGoods: string | null = null

  @JsonProperty('allSeatsForwardFacing', Boolean)
  allSeatsForwardFacing: boolean | null = null

  @JsonProperty('allSeatsHaveSeatbelts', Boolean)
  allSeatsHaveSeatbelts: boolean | null = null

  @JsonProperty('seatbeltDetails', String)
  seatbeltDetails: string | null = null

  @JsonProperty('leftHandDriveReason', String)
  leftHandDriveReason: string | null = null

  @JsonProperty('seatDetails', String)
  seatDetails: string | null = null

  @JsonProperty('vehicleUse', DefinedListDetail)
  vehicleUse: DefinedListDetail

  @JsonProperty('vehicleStorage', DefinedListDetail)
  vehicleStorage: DefinedListDetail

  @JsonProperty('wheelchairsAdapted', Boolean)
  wheelchairsAdapted: boolean | null = null

  @JsonProperty('wheelchairsAdaptedDescription', String)
  wheelchairsAdaptedDescription: string | null = null

  @JsonProperty('anchoringLocking', Boolean)
  anchoringLocking: boolean | null = null

  @JsonProperty('selfLoadDevice', Boolean)
  selfLoadDevice: boolean | null = null

  @JsonProperty('modifications', [Modification])
  modifications: Modification[]

  @JsonProperty('commercialVehicleBodyType', DefinedListDetail)
  commercialVehicleBodyType: DefinedListDetail

  @JsonProperty('workRadius', DefinedListDetail)
  workRadius: DefinedListDetail

  @JsonProperty('numberOfDrops', DefinedListDetail)
  numberOfDrops: DefinedListDetail

  @JsonProperty('fuelType', DefinedListDetail)
  fuelType: DefinedListDetail

  @JsonProperty('vehicleType', DefinedListDetail)
  vehicleType: DefinedListDetail

  @JsonProperty('isVehicleKeptOutsideUK', Boolean)
  isVehicleKeptOutsideUK: boolean | null = null

  @JsonProperty('keptAtHomeAddress', Boolean)
  keptAtHomeAddress: boolean | null = null

  @JsonProperty('secondaryStorageAddress', Address)
  secondaryStorageAddress: Address

  @JsonProperty('isModified', Boolean)
  isModified: boolean | null = null

  constructor(
    id = 0,
    registrationNumber = '',
    vehicleType = new DefinedListDetail(),
    value = 0,
    yearOfManufacture = 0,
    purchaseDate = new Date(),
    car = new Car(),
    registeredKeeper = new DefinedListDetail(),
    address = new Address(),
    registeredOwner = new DefinedListDetail(),
    overnightParkLocation = new DefinedListDetail(),
    annualMileage = 0,
    businessMileage = 0,
    leftHandDrive = false,
    cubicCapacity = 0,
    motorhomeType = null,
    imported = false,
    noOfSeats = 0,
    vehicleTransmission = new DefinedListDetail(),
    alarmType = new DefinedListDetail(),
    alarmModel = new DefinedListDetail(),
    alarmFittedBy = new DefinedListDetail(),
    modifiedEngineOrDrive = false,
    conversionCompleted = false,
    registeredDVLAMotorhome = false,
    trackerType = new DefinedListDetail(),
    trackerModel = new DefinedListDetail(),
    unknownMotorhomeType = null,
    bodyType = '',
    vehicleWeight = 0,
    workRadius = new DefinedListDetail(),
    numberOfDrops = new DefinedListDetail(),
    fuelType = new DefinedListDetail(),
    unknownABICode = null,
    commercialVehicleBodyType = new DefinedListDetail(),
    isVehicleKeptOutsideUK = false,
    secondaryStorageAddress = new Address(),
    isModified = null,
    isVehicleRegisteredInUK = null,
    isUsedForPrivateHire = null,
    vehicleUse = new DefinedListDetail(),
    hasTrailer = null,
    trailerDetails = '',
    trailerMaxCapacity = null,
    trailerValue = null,
    trailerVIN = '',
    carriesDangerousGoods = null,
    storedGoodsDetails = '',
    typeOfGoods = '',
    allSeatsForwardFacing = null,
    allSeatsHaveSeatbelts = null,
    seatbeltDetails = '',
    seatDetails = '',
    leftHandDriveReason = '',
    wheelchairsAdapted = null,
    wheelchairsAdaptedDescription = '',
    selfLoadDevice = null,
    anchoringLocking = null,
    sectionPermit = null,
    loanWithPermits = null,
    roadsideProhibition = null,
    percentageContract = null,
    modifications: Modification[] = [],
    vehicleStorage = new DefinedListDetail(),
    keptAtHomeAddress = null,
  ) {
    super(id)
    this.registrationNumber = registrationNumber
    this.value = value
    this.vehicleType = vehicleType
    this.yearOfManufacture = yearOfManufacture
    this.purchaseDate = purchaseDate
    this.car = car
    this.registeredKeeper = registeredKeeper
    this.registeredOwner = registeredOwner
    this.overnightParkLocation = overnightParkLocation
    this.address = address
    this.annualMileage = annualMileage
    this.businessMileage = businessMileage
    this.leftHandDrive = leftHandDrive
    this.cubicCapacity = cubicCapacity
    this.imported = imported
    this.noOfSeats = noOfSeats
    this.vehicleTransmission = vehicleTransmission
    this.alarmType = alarmType
    this.alarmModel = alarmModel
    this.alarmFittedBy = alarmFittedBy
    this.trackerType = trackerType
    this.trackerModel = trackerModel
    this.conversionCompleted = conversionCompleted
    this.modifiedEngineOrDrive = modifiedEngineOrDrive
    this.registeredDVLAMotorhome = registeredDVLAMotorhome
    this.motorhomeType = motorhomeType
    this.unknownMotorhomeType = unknownMotorhomeType
    this.bodyType = bodyType
    this.vehicleWeight = vehicleWeight
    this.workRadius = workRadius
    this.numberOfDrops = numberOfDrops
    this.fuelType = fuelType
    this.unknownABICode = unknownABICode
    this.commercialVehicleBodyType = commercialVehicleBodyType
    this.isVehicleKeptOutsideUK = isVehicleKeptOutsideUK
    this.secondaryStorageAddress = secondaryStorageAddress
    this.isModified = isModified
    this.isVehicleRegisteredInUK = isVehicleRegisteredInUK
    this.isUsedForPrivateHire = isUsedForPrivateHire
    this.typeOfGoods = typeOfGoods
    this.storedGoodsDetails = storedGoodsDetails
    this.carriesDangerousGoods = carriesDangerousGoods
    this.trailerVIN = trailerVIN
    this.trailerValue = trailerValue
    this.trailerMaxCapacity = trailerMaxCapacity
    this.trailerDetails = trailerDetails
    this.hasTrailer = hasTrailer
    this.allSeatsForwardFacing = allSeatsForwardFacing
    this.allSeatsHaveSeatbelts = allSeatsHaveSeatbelts
    this.seatbeltDetails = seatbeltDetails
    this.seatDetails = seatDetails
    this.vehicleUse = vehicleUse
    this.leftHandDriveReason = leftHandDriveReason
    this.wheelchairsAdapted = wheelchairsAdapted
    this.wheelchairsAdaptedDescription = wheelchairsAdaptedDescription
    this.anchoringLocking = anchoringLocking
    this.selfLoadDevice = selfLoadDevice
    this.loanWithPermits = loanWithPermits
    this.percentageContract = percentageContract
    this.sectionPermit = sectionPermit
    this.roadsideProhibition = roadsideProhibition
    this.modifications = modifications
    this.vehicleStorage = vehicleStorage
    this.keptAtHomeAddress = keptAtHomeAddress
  }
}

@JsonObject('CarRisk')
export class CarRisk extends Risk {
  @JsonProperty('proposer', ProposerDriver)
  proposer: ProposerDriver

  @JsonProperty('additionalDrivers', [AdditionalDriver])
  additionalDrivers: AdditionalDriver[]

  @JsonProperty('vehicle', CarRiskVehicle)
  vehicle: CarRiskVehicle

  @JsonProperty('voluntaryExcess', DefinedListDetail)
  voluntaryExcess: DefinedListDetail

  @JsonProperty('noClaimsProtection', Boolean)
  noClaimsProtection: boolean

  @JsonProperty('coverType', DefinedListDetail)
  coverType: DefinedListDetail

  @JsonProperty('coverTerm', DefinedListDetail)
  coverTerm: DefinedListDetail

  @JsonProperty('policyClass', DefinedListDetail)
  policyClass: DefinedListDetail

  @JsonProperty('currentInsurer', DefinedListDetail)
  currentInsurer: DefinedListDetail

  @JsonProperty('policyEndDate', DateConverter)
  policyEndDate: Date

  @JsonProperty('claimFreeYears', Number)
  claimFreeYears: number

  @JsonProperty('policyDuration', DefinedListDetail)
  policyDuration: DefinedListDetail

  @JsonProperty('classOfUse', DefinedListDetail)
  classOfUse: DefinedListDetail

  @JsonProperty('isInsuredAnnually', Boolean)
  isInsuredAnnually: boolean

  @JsonProperty('isImpounded', Boolean)
  isImpounded: boolean

  @JsonProperty('drivingOtherCarsOnOtherPolicy', Boolean)
  drivingOtherCarsOnOtherPolicy: boolean

  @JsonProperty('registeredOutsideUKReason', String)
  registeredOutsideUKReason: string | null = null

  @JsonProperty('userSpecifiedNumberOfDrivers', DefinedListDetail)
  userSpecifiedNumberOfDrivers: DefinedListDetail

  @JsonProperty('reasonForCover', DefinedListDetail)
  reasonForCover: DefinedListDetail

  constructor(
    id = 0,
    effectiveDate = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    productUniqueId = '',
    proposer = new ProposerDriver(),
    additionalDrivers: AdditionalDriver[] = [],
    vehicle = new CarRiskVehicle(),
    voluntaryExcess = new DefinedListDetail(),
    noClaimsProtection = true,
    coverType = new DefinedListDetail(),
    policyDuration = new DefinedListDetail(),
    classOfUse = new DefinedListDetail(),
    policyClass = new DefinedListDetail(),
    currentInsurer = new DefinedListDetail(),
    claimFreeYears = 0,
    policyEndDate = new Date(),
    isInusredAnnually = true,
    isImpounded = false,
    drivingOtherCarsOnOtherPolicy = true,
    coverTerm = new DefinedListDetail(),
    registeredOutsideUKReason = '',
    userSpecifiedNumberOfDrivers = new DefinedListDetail(),
    reasonForCover = new DefinedListDetail(),
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule)
    this.proposer = proposer
    this.additionalDrivers = additionalDrivers
    this.vehicle = vehicle
    this.voluntaryExcess = voluntaryExcess
    this.noClaimsProtection = noClaimsProtection
    this.currentInsurer = currentInsurer
    this.coverType = coverType
    this.policyDuration = policyDuration
    this.classOfUse = classOfUse
    this.policyClass = policyClass
    this.claimFreeYears = claimFreeYears
    this.policyEndDate = policyEndDate
    this.isInsuredAnnually = isInusredAnnually
    this.isImpounded = isImpounded
    this.drivingOtherCarsOnOtherPolicy = drivingOtherCarsOnOtherPolicy
    this.coverTerm = coverTerm
    this.registeredOutsideUKReason = registeredOutsideUKReason
    this.userSpecifiedNumberOfDrivers = userSpecifiedNumberOfDrivers
    this.reasonForCover = reasonForCover
  }
}

@JsonObject('GapVehicle')
export class GapVehicle extends Model {
  @JsonProperty('registrationNumber', String)
  registrationNumber: string

  @JsonProperty('vehicleType', DefinedListDetail)
  vehicleType: DefinedListDetail

  @JsonProperty('vin', String)
  vin: string

  @JsonProperty('manufacturer', String)
  manufacturer: string

  @JsonProperty('fuelType', DefinedListDetail)
  fuelType: DefinedListDetail

  @JsonProperty('model', String)
  model: string

  @JsonProperty('transmission', DefinedListDetail)
  transmission: DefinedListDetail

  @JsonProperty('modelYear', Number)
  modelYear: number

  @JsonProperty('aspiration', String)
  aspiration: string

  @JsonProperty('engineSize', Number)
  engineSize: number

  @JsonProperty('dateFirstRegistered', DateConverter)
  dateFirstRegistered: Date

  @JsonProperty('vehiclePurchaseDate', DateConverter)
  vehiclePurchaseDate: Date

  @JsonProperty('currentMileage', Number)
  currentMileage: number

  @JsonProperty('invoiceValue', Number)
  invoiceValue: number

  @JsonProperty('insuringInNameOfCompany', Boolean)
  insuringInNameOfCompany: boolean

  @JsonProperty('deposit', Number)
  deposit: number

  @JsonProperty('totalFinanced', Number)
  totalFinanced: number

  @JsonProperty('finalPayment', Number)
  finalPayment: number

  @JsonProperty('vehicleUse', DefinedListDetail)
  vehicleUse: DefinedListDetail

  @JsonProperty('vehicleCollectionDate', DateConverter)
  vehicleCollectionDate: Date

  @JsonProperty('car', Car)
  car: Car

  @JsonProperty('vehiclePurchaseFrom', DefinedListDetail)
  vehiclePurchaseFrom: DefinedListDetail

  @JsonProperty('vehiclePaymentType', DefinedListDetail)
  vehiclePaymentType: DefinedListDetail

  @JsonProperty('newOrUsed', DefinedListDetail)
  newOrUsed: DefinedListDetail

  @JsonProperty('vehicleCollectionDateKnown', DefinedListDetail)
  vehicleCollectionDateKnown: DefinedListDetail

  @JsonProperty('vehiclePaymentType', DefinedListDetail)
  ukSpecification: DefinedListDetail

  constructor(
    id = 0,
    registrationNumber = '',
    vehicleType = new DefinedListDetail(),
    vin = '',
    manufacturer = '',
    model = '',
    fuelType = new DefinedListDetail(),
    transmission = new DefinedListDetail(),
    modelYear = 0,
    aspiration = '',
    engineSize = 0,
    dateFirstRegistered = new Date(),
    vehiclePurchaseDate = new Date(),
    currentMileage = 0,
    invoiceValue = 0,
    deposit = 0,
    insuringInNameOfCompany = false,
    totalFinanced = 0,
    finalPayment = 0,
    vehicleUse = new DefinedListDetail(),
    vehicleCollectionDate = new Date(),
    car = new Car(),
    vehiclePurchaseFrom = new DefinedListDetail(),
    vehiclePaymentType = new DefinedListDetail(),
    newOrUsed = new DefinedListDetail(),
    vehicleCollectionDateKnown = new DefinedListDetail(),
    ukSpecification = new DefinedListDetail(),
  ) {
    super(id)
    this.registrationNumber = registrationNumber
    this.vehicleType = vehicleType
    this.vin = vin
    this.manufacturer = manufacturer
    this.model = model
    this.fuelType = fuelType
    this.transmission = transmission
    this.modelYear = modelYear
    this.aspiration = aspiration
    this.engineSize = engineSize
    this.dateFirstRegistered = dateFirstRegistered
    this.vehiclePurchaseDate = vehiclePurchaseDate
    this.currentMileage = currentMileage
    this.invoiceValue = invoiceValue
    this.vehicleUse = vehicleUse
    this.vehicleCollectionDate = vehicleCollectionDate
    this.car = car
    this.vehiclePurchaseFrom = vehiclePurchaseFrom
    this.vehiclePaymentType = vehiclePaymentType
    this.newOrUsed = newOrUsed
    this.vehicleCollectionDateKnown = vehicleCollectionDateKnown
    this.ukSpecification = ukSpecification
    this.deposit = deposit
    this.totalFinanced = totalFinanced
    this.finalPayment = finalPayment
    this.insuringInNameOfCompany = insuringInNameOfCompany
  }
}

@JsonObject('TouringCaravanRisk')
export class TouringCaravanRisk extends Risk {
  @JsonProperty('awningsValue', Number)
  awningsValue: number

  @JsonProperty('equipmentValue', Number)
  equipmentValue: number

  @JsonProperty('contentsValue', Number)
  contentsValue: number

  @JsonProperty('personalEffectsValue', Number)
  personalEffectsValue: number

  @JsonProperty('voluntaryExcess', DefinedListDetail)
  voluntaryExcess: DefinedListDetail

  @JsonProperty('coverType', DefinedListDetail)
  coverType: DefinedListDetail

  @JsonProperty('touringCaravanProposer', TouringCaravanProposer)
  proposer: TouringCaravanProposer

  @JsonProperty('touringCaravans', [TouringCaravan])
  touringCaravans: TouringCaravan[]

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate: Date = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    awningsValue = 0,
    equipmentValue = 0,
    contentsValue = 0,
    personalEffectsValue = 0,
    voluntaryExcess = new DefinedListDetail(),
    proposer = new TouringCaravanProposer(),
    touringCaravans = [],
    coverType = new DefinedListDetail(),
    breakDownCoverRequired = null,
    vapVehicle = new VAPVehicle(),
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule, vapVehicle, breakDownCoverRequired)
    this.awningsValue = awningsValue
    this.equipmentValue = equipmentValue
    this.contentsValue = contentsValue
    this.personalEffectsValue = personalEffectsValue
    this.voluntaryExcess = voluntaryExcess
    this.proposer = proposer
    this.touringCaravans = touringCaravans
    this.coverType = coverType
  }
}

@JsonObject('CaravanRisk')
export class CaravanRisk extends Risk {
  @JsonProperty('caravanProposer', CaravanProposer)
  proposer: CaravanProposer

  @JsonProperty('caravanCover', CaravanCover)
  cover: CaravanCover

  @JsonProperty('caravans', [Caravan])
  caravans: Caravan[]

  @JsonProperty('additionalPolicyholders', [AdditionalPolicyholder])
  additionalPolicyholders: AdditionalPolicyholder[]

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate: Date = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    proposer = new CaravanProposer(),
    cover = new CaravanCover(),
    caravans = [],
    additionalPolicyholders = [],
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule)
    this.proposer = proposer
    this.cover = cover
    this.caravans = caravans
    this.additionalPolicyholders = additionalPolicyholders
  }
}

@JsonObject('GapRisk')
export class GapRisk extends Risk {
  @JsonProperty('demands', GapDemands)
  demands: GapDemands

  @JsonProperty('proposer', GapProposer)
  proposer: GapProposer

  @JsonProperty('coverTerm', DefinedListDetail)
  coverTerm: DefinedListDetail

  @JsonProperty('vehicle', GapVehicle)
  vehicle: GapVehicle

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate: Date = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    proposer = new GapProposer(),
    demands = new GapDemands(),
    coverTerm = new DefinedListDetail(),
    vehicle = new GapVehicle(),
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule)
    this.demands = demands
    this.proposer = proposer
    this.coverTerm = coverTerm
    this.vehicle = vehicle
  }
}

@JsonObject('PetRisk')
export class PetRisk extends Risk {
  @JsonProperty('proposer', PetProposer)
  proposer: PetProposer

  @JsonProperty('pets', [PetRiskPet])
  pets: PetRiskPet[]

  @JsonProperty('coverLevelOrType', DefinedListDetail)
  coverLevelOrType: DefinedListDetail

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate: Date = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    proposer = new PetProposer(),
    pets: PetRiskPet[] = [],
    coverLevelOrType = new DefinedListDetail(),
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule)
    this.proposer = proposer
    this.pets = pets
    this.coverLevelOrType = coverLevelOrType
  }
}

@JsonObject('PropertyRisk')
export class PropertyRisk extends Risk {
  @JsonProperty('propertyRiskId', Number, true)
  propertyRiskId: number | null = null

  @JsonProperty('properties', [Property])
  properties: Property[]

  @JsonProperty('ncdBuildings', DefinedListDetail)
  ncdBuildings: DefinedListDetail

  @JsonProperty('ncdContents', DefinedListDetail)
  ncdContents: DefinedListDetail

  @JsonProperty('additionalPolicyholders', [AdditionalPolicyholder])
  additionalPolicyholders: AdditionalPolicyholder[]

  constructor(
    id = 0,
    productUniqueId = '',
    effectiveDate: Date = new Date(),
    address = new Address(),
    businessSource = new CustomList(),
    coInsuranceRule = new CoInsuranceRule(),
    riskProposer = new RiskProposer(),
    properties: Property[] = [],
    ncdBuildings = new DefinedListDetail(),
    ncdContents = new DefinedListDetail(),
    additionalPolicyholders: AdditionalPolicyholder[] = [],
  ) {
    super(id, productUniqueId, effectiveDate, address, businessSource, coInsuranceRule, undefined, riskProposer)
    this.properties = properties
    this.ncdBuildings = ncdBuildings
    this.ncdContents = ncdContents
    this.additionalPolicyholders = additionalPolicyholders
  }

  get proposer() {
    return this.riskProposer
  }
}

import { JsonObject, JsonProperty } from 'json2typescript'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PropertyClaimValue')
export default class PropertyClaim {
  @JsonProperty('propertyClaimId', Number, true)
  propertyClaimId: number | null = null

  @JsonProperty('claimDate', DateConverter)
  claimDate: Date = new Date()

  @JsonProperty('value', Number)
  value = 0

  @JsonProperty('happenedAtProperty', Boolean)
  happenedAtProperty = false

  @JsonProperty('claimType', String)
  claimType: string | null = null

  @JsonProperty('claimCause', String)
  claimCause: string | null = null

  @JsonProperty('claimStatus', String)
  claimStatus: string | null = null
}

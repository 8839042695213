import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Routes from './constants/Routes'
import DisplayMode from './constants/DisplayMode'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: Routes.home,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Home`,
        displayMode: DisplayMode.portal,
      },
      component: Home,
    },
    {
      path: '/policies',
      name: Routes.policies,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Policies`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Policies.vue'),
    },
    {
      path: '/quotes',
      name: Routes.quotes,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Quotes`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Quotes.vue'),
    },
    {
      path: '/claims',
      name: Routes.claims,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Claims`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Claims.vue'),
    },
    {
      path: '/policies/:product/:policyNumber',
      name: Routes.policy,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Policy`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Policy.vue'),
    },
    {
      path: '/policies/:product/:policyNumber/upgrade',
      name: Routes.mtaUpgrade,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Upgrade`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/PolicyUpgrade.vue'),
    },
    {
      path: '/policies/:product/:policyNumber/cancel',
      name: Routes.cancellation,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Cancel Policy`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Cancellation.vue'),
    },
    {
      path: '/policies/:product/:policyNumber/cancel-mta',
      name: Routes.cancelPendingMTA,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Cancel Pending Change`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/CancelPendingMTA.vue'),
    },
    {
      path: '/account',
      name: Routes.account,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Account`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Account.vue'),
    },
    {
      path: '/login',
      name: Routes.login,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Login`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/deep-link/:token',
      name: Routes.deepLink,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – DeepLink`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/DeepLink.vue'),
    },
    {
      path: '/renewal/:product/:policyNumber/:quoteReference?',
      name: Routes.renewal,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal`,
        displayMode: DisplayMode.journey,
      },
      component: () => import('./views/RenewalJourney.vue'),
      props: {
        baseRoute: Routes.renewal,
        mappings: 'renewalPageMappings',
        journey: 'renewalJourneyPortal',
      },
      children: [
        {
          path: 'scheme',
          name: Routes.renewalScheme,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'vehicle-details',
          name: Routes.renewalVehicleDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Vehicle Details Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'pet-details',
          name: Routes.renewalPetDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Pet Details Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details',
          name: Routes.renewalProposerDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'renewal-home-contents',
          name: Routes.renewalhomeContentsOccupancyAndSecurity,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Home Contents Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'renewal-home-your-home',
          name: Routes.renewalhomeYourHome,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Your Home Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details-section-1',
          name: Routes.renewalProposerSection1,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details-section-2',
          name: Routes.renewalProposerSection2,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details-section-3',
          name: Routes.renewalProposerSection3,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details-section-4',
          name: Routes.renewalProposerSection4,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'proposer-details-section-5',
          name: Routes.renewalProposerSection5,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Proposer Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'driver-details',
          name: Routes.renewalDriverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Driver Details`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'assumptions',
          name: Routes.renewalAssumptions,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Assumptions`,
          },
          component: () => import('@/components/renewal-journey/RenewalDeclarations.vue'),
        },
        {
          path: 'cover-details',
          name: Routes.renewalCoverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Cover Details Questions`,
          },
          component: () => import('@/components/renewal-journey/GenericRenewalQuestions.vue'),
        },
        {
          path: 'declarations',
          name: Routes.renewalDeclarations,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Declarations`,
          },
          component: () => import('@/components/renewal-journey/RenewalDeclarations.vue'),
        },
        {
          path: ':quoteReference/summary',
          name: Routes.renewalQuoteSummary,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Summary`,
          },
          component: () => import('@/components/nb-journey/Summary.vue'),
        },
        {
          path: 'premium',
          name: Routes.renewalPremium,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Premium`,
          },
          component: () => import('@/components/renewal-journey/Premium.vue'),
        },
        {
          path: 'payment',
          name: Routes.renewalPayment,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Payment`,
          },
          component: () => import('@/components/renewal-journey/Payment.vue'),
        },
        {
          path: 'upgrade',
          name: Routes.renewalUpgrade,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Upgrade`,
          },
          component: () => import('@/components/nb-journey/PolicyUpgrade.vue'),
        },
        {
          path: 'upgrade-payment',
          name: Routes.renewalUpgradePayment,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Upgrade Payment`,
          },
          component: () => import('@/components/mta-journey/Payment.vue'),
        },
        {
          path: 'payment-confirmation',
          name: Routes.renewalPaymentConfirmation,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Renewal Confirmed`,
          },
          component: () => import('@/components/renewal-journey/PaymentConfirmation.vue'),
        },
      ],
    },
    {
      path: '/journey/:product',
      name: Routes.productJourney,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Journey`,
        displayMode: DisplayMode.journey,
      },
      component: () => import('./views/NBJourney.vue'),
      props: {
        baseRoute: Routes.productJourney,
        mappings: 'nbPageMappings',
        journey: 'navigationJourneyPortal',
      },
      children: [
        {
          path: 'declarations',
          name: Routes.preQuoteDeclarations,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Declarations`,
          },
          component: () => import('@/components/nb-journey/Declarations.vue'),
        },
        {
          path: 'demands',
          name: Routes.demands,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Demands`,
          },
          component: () => import('@/components/nb-journey/DemandsQuestions.vue'),
        },
        {
          path: 'scheme/:code?',
          name: Routes.scheme,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'home-contents',
          name: Routes.homeContentsOccupancyAndSecurity,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-details',
          name: Routes.vehicleDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-details-section-1',
          name: Routes.vehicleSection1,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-details-section-2',
          name: Routes.vehicleSection2,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-details-section-3',
          name: Routes.vehicleSection3,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-to-insure',
          name: Routes.vehicleToInsure,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-licence-info',
          name: Routes.proposerLicenceInfo,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'additional-driver-details',
          name: Routes.additionalDriverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Additional Driver Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'owning-and-using',
          name: Routes.owningAndUsing,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Owning and Using`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'pet-details',
          name: Routes.petDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Pet Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details',
          name: Routes.proposerDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details-section-1',
          name: Routes.proposerSection1,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details-section-2',
          name: Routes.proposerSection2,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details-section-3',
          name: Routes.proposerSection3,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details-section-4',
          name: Routes.proposerSection4,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'proposer-details-section-5',
          name: Routes.proposerSection5,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'driver-details',
          name: Routes.driverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Driver Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: 'vehicle-summary',
          name: Routes.vehicleSummary,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Summary`,
          },
          component: () => import('@/components/nb-journey/VehicleDetailsSummary.vue'),
        },
        {
          path: 'assumptions',
          name: Routes.preQuoteAssumptions,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Assumptions`,
          },
          component: () => import('@/components/nb-journey/Declarations.vue'),
        },
        {
          path: ':quoteReference/assumptions',
          name: Routes.assumptions,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Assumptions`,
          },
          component: () => import('@/components/nb-journey/Declarations.vue'),
        },
        {
          path: 'cover-details',
          name: Routes.coverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Cover Details`,
          },
          component: () => import('@/components/nb-journey/GenericNBQuestions.vue'),
        },
        {
          path: ':quoteReference/premium/:code?',
          name: Routes.premium,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Premium`,
          },
          component: () => import('@/components/nb-journey/Premium.vue'),
        },
        {
          path: ':quoteReference/summary',
          name: Routes.quoteSummary,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Summary`,
          },
          component: () => import('@/components/nb-journey/Summary.vue'),
        },
        {
          path: ':quoteReference/quote',
          name: Routes.quote,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Quote`,
          },
          component: () => import('@/components/nb-journey/Quote.vue'),
        },
        {
          path: ':quoteReference/declarations',
          name: Routes.declarations,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Declarations`,
          },
          component: () => import('@/components/nb-journey/Declarations.vue'),
        },
        {
          path: ':quoteReference/additional-products',
          name: Routes.additionalProducts,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Additional Products`,
          },
          component: () => import('@/components/nb-journey/AdditionalProducts.vue'),
        },
        {
          path: ':quoteReference/payment',
          name: Routes.payment,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Payment`,
          },
          component: () => import('@/components/nb-journey/Payment.vue'),
        },
        {
          path: 'upgrade/:policyNumber',
          name: Routes.upgrade,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Upgrade`,
          },
          component: () => import('@/components/nb-journey/PolicyUpgrade.vue'),
        },
        {
          path: 'upgrade/:policyNumber/:quoteReference/payment',
          name: Routes.upgradePayment,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Payment`,
          },
          component: () => import('@/components/mta-journey/Payment.vue'),
        },
        {
          path: 'payment-confirm/:policyNumber',
          name: Routes.paymentConfirm,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Payment Confirmed`,
          },
          component: () => import('@/components/nb-journey/PaymentConfirmation.vue'),
        },
      ],
    },
    {
      path: '/aggregator/:guid?',
      name: Routes.aggregatorLanding,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Welcome`,
        displayMode: DisplayMode.journey,
      },
      component: () => import('@/views/AggregatorLanding.vue'),
    },
    {
      path: '/aggregator-landing',
      name: Routes.aggregatorLanding,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Welcome`,
        displayMode: DisplayMode.journey,
      },
      component: () => import('@/views/AggregatorLanding.vue'),
    },
    {
      path: '/go-cardless-redirect-flow-complete',
      name: Routes.goCardlessRedirectComplete,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – GoCardless Redirect Complete`,
      },
      component: () => import('@/views/GoCardlessRedirectComplete.vue'),
    },
    {
      path: '/policy-change/:product/:policyNumber',
      name: Routes.policyChange,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Journey`,
        displayMode: DisplayMode.journey,
      },
      component: () => import('./views/MTAJourney.vue'),
      props: {
        baseRoute: Routes.policyChange,
        mappings: 'mtaPageMappings',
        journey: 'policyChangeJourneyPortal',
      },
      children: [
        {
          path: 'scheme/:code?',
          name: Routes.mtaScheme,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'vehicle-details/:code?',
          name: Routes.mtaVehicleDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Vehicle Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'owning-and-using/:code?',
          name: Routes.mtaOwningAndUsing,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Owning And Using`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'pet-details/:code?',
          name: Routes.mtaPetDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Pet Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details/:code?',
          name: Routes.mtaProposerDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'cover-details/:code?',
          name: Routes.mtaCoverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Cover Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: ':quoteReference/premium',
          name: Routes.mtaPremium,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Premium`,
          },
          component: () => import('@/components/mta-journey/Premium.vue'),
        },
        {
          path: ':quoteReference/payment',
          name: Routes.mtaPayment,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Payment`,
          },
          component: () => import('@/components/mta-journey/Payment.vue'),
        },
        {
          path: 'confirm',
          name: Routes.mtaConfirm,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Change Confirmed`,
          },
          component: () => import('@/components/mta-journey/Confirm.vue'),
        },
        {
          path: ':quoteReference/assumptions',
          name: Routes.mtaAssumptions,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Assumptions`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: ':quoteReference/declarations',
          name: Routes.mtaDeclarations,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Declarations`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'assumptions',
          name: Routes.mtaPreQuoteAssumptions,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Assumptions`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'declarations',
          name: Routes.mtaPreQuoteDeclarations,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Declarations`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details-section-1',
          name: Routes.mtaProposerSection1,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details-section-2',
          name: Routes.mtaProposerSection2,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details-section-3',
          name: Routes.mtaProposerSection3,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details-section-4',
          name: Routes.mtaProposerSection4,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'proposer-details-section-5',
          name: Routes.mtaProposerSection5,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Proposer Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'driver-details',
          name: Routes.mtaDriverDetails,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – MTA Driver Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },
        {
          path: 'home-contents',
          name: Routes.mtahomeContentsOccupancyAndSecurity,
          meta: {
            title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Details`,
          },
          component: () => import('@/components/mta-journey/GenericMTAQuestions.vue'),
        },

      ],
    },
    {
      path: '/quote-declined/:quoteReference?',
      name: Routes.quoteDeclined,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Quote Declined`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('@/components/QuoteDeclined.vue'),
    },
    {
      path: '/quote-expired',
      name: Routes.quoteExpired,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Quote Expired`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('@/components/QuoteExpired.vue'),
    },
    {
      path: '/quote-referred/:quoteReference?',
      name: Routes.quoteReferred,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Quote Referred`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('@/components/QuoteRefer.vue'),
    },
    // Always make sure these error pages are last
    {
      path: '*',
      name: Routes.notFound,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Not Found`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Error.vue'),
      props: {
        errorCode: 404,
      },
    },
    {
      path: '/server-error',
      name: Routes.serverError,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Server Error`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/Error.vue'),
      props: {
        errorCode: 500,
      },
    },
    {
      path: '/session-expired',
      name: Routes.sessionExpired,
      meta: {
        title: `${window.injectedEnv.FRONTEND_TENANT_FRIENDLY_NAME} – Session Expired`,
        displayMode: DisplayMode.portal,
      },
      component: () => import('./views/SessionExpired.vue'),
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    return undefined
  },
})

router.beforeEach((to, from, next) => {
  if (router.app) {
    switch (to.name) {
      case Routes.home:
      case Routes.quotes:
      case Routes.policies:
      case Routes.claims:
      case Routes.login:
      case Routes.account:
        if (router.app.$configurations.features.portal)
          next()
        else
          next({ name: Routes.notFound })

        break
      default:
        next()
    }
  }
})

export default router

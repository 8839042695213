import { JsonObject, JsonProperty } from 'json2typescript'
import Model from '../Model'
import { DefinedListDetail } from '../DefinedList'

@JsonObject('SpecifiedItem')
export default class SpecifiedItem extends Model {
  @JsonProperty('propertyNamedItemId', Number, true)
    propertyNamedItemId: number | null = null

  @JsonProperty('typeOfItem', DefinedListDetail)
    typeOfItem: DefinedListDetail

  @JsonProperty('insuredItemsCarriedOutsideProperty', Boolean)
  insuredItemsCarriedOutsideProperty: boolean

  insuredItemsCarriedOutsidePropertytext: string | null = null

  @JsonProperty('replacementCost', Number)
    replacementCost: number

  @JsonProperty('specifiedItemDescription', String)
    specifiedItemDescription: string

  constructor(
    id = 0,
    typeOfItem = new DefinedListDetail(),
    insuredItemsCarriedOutsideProperty = false,
    replacementCost = 0,
    specifiedItemDescription = '',
    insuredItemsCarriedOutsidePropertytext = '',
  ) {
    super(id)
    this.typeOfItem = typeOfItem
    this.insuredItemsCarriedOutsideProperty = insuredItemsCarriedOutsideProperty
    this.replacementCost = replacementCost
    this.specifiedItemDescription = specifiedItemDescription
    this.insuredItemsCarriedOutsidePropertytext = insuredItemsCarriedOutsidePropertytext
  }
}
